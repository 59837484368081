<template>
    <v-container>
            <v-row justify="center" class="py-5">
                <h1 class="display-1 font-weight-light">Search Users</h1>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="px-10 pb-5">
                        <v-card-text class="title text-center">Search Criteria</v-card-text>
                        <v-divider class="mx-5 mb-10"></v-divider>
                        <v-btn class="blue darken-2" @click="search">Search</v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5" v-if="isDataAvailable">
                <v-data-table dense :headers="headers" :items="userList" item-key="id">
                </v-data-table>
            </v-row>
    </v-container>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';

export default {
    components: {
    },
    data() {
        return {
            headers: [
                {
                    text: 'id',
                    value: 'id',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                    divider: true,
                },
                {
                    text: 'name',
                    value: 'name',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                    divider: true,
                },
            ],
            userList: [],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isDataAvailable() {
            return this.userList && this.userList.length > 0;
        },
    },
    methods: {
        clear() {
            while (this.userList.length > 0) {
                this.userList.splice(-1);
            }
        },
        async search() {
            console.log('searching...');
            const report = await this.$client.data.report({ reportId: 1 });
            console.log(`data: ${JSON.stringify(report)}`);
            this.clear();
            for (let i = 0; i < report.data.length; i += 1) {
                const { id, content } = report.data[i];
                const item = {
                    id,
                    name: content.name,
                };
                this.userList.push(item);
            }
        },
    },
};
</script>
